import { useAppContext } from 'components/common/AppProvider';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { createSignOutAction } from 'store/AppContext/AppContextActions';
import { getNavigateAs, removeNavigateAs, setNavigateAs } from 'services/NavigateAsService';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { InvestRecoSelectors } from 'store/Normalizr/InvestRecoSelectors';
import { toastError } from 'components/common/Toaster';
import { currentUserCanManageAll } from 'services/RightsService';
import { getNavigateAsPersons } from 'store/Persons/PersonsThunk';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-account-center': {
                    id?: string;
                    'available-languages'?: string;
                    'user-dropdown-links'?: any;
                    authentication?: string;
                    environment?: string;
                    language?: string;
                    mode?: 'sg-markets';
                    debug?: string;
                    ref?: (elt: HTMLElement) => void;
                    'show-sign-in-button'?: boolean;
                    'navigate-as'?: boolean;
                    'navigate-as-list'?: string;
                    'navigate-as-user'?: string;
                };
            }
        }
    }

    interface DocumentEventMap {
        [navigateAsEventName]: CustomEvent<INavigateAsEvent>;
        [stopNavigateAsEventName]: Event;
        [signOutEventName]: MouseEvent;
    }
}

const signOutEventName = 'sgwt-account-center--sign-out';
const navigateAsEventName = 'sgwt-account-center--navigate-as-select-user';
const stopNavigateAsEventName = 'sgwt-account-center--stop-navigation-as';

interface INavigateAsEvent {
    user: {
        id: string,
        name: string,
        company: string,
    }
}

export const SgwtAccountCenter: React.FC = () => {
    const navigate = useNavigate();
    const { dispatch, state: { appContext: { environment, loggedUserIcid, isFetching, didInvalidate }, entities, persons: { navigateAs } } } = useAppContext();

    const loggedUser = InvestRecoSelectors.getLoggedUser(loggedUserIcid, entities.investReco);

    if (loggedUserIcid && !loggedUser?.impersonatedBy && getNavigateAs() && !isFetching && !didInvalidate) {
        setTimeout(() => {
            toastError('Impersonation failed Please come back later to use this functionality', 'Impersonation Failed');
            removeNavigateAs();
        }, 3000);
    }

    const navigateAsPersons = InvestRecoSelectors.getPersonArray(navigateAs.data, entities.investReco);
    const canNavigateAs = currentUserCanManageAll(loggedUser?.impersonatedBy?.permissions || loggedUser?.permissions);

    const handleSignOut = () => {
        dispatch(createSignOutAction());
        navigate(RoutePaths.Home.url());
    };

    const handleNavigateAs = (event: Event) => {
        const customEvent = event as CustomEvent<INavigateAsEvent>;
        setNavigateAs(customEvent.detail.user.id);
    };

    const handleStopNavigateAs = () => {
        removeNavigateAs();
    };

    const widget: Element | null = document.querySelector('sgwt-account-center');

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);

        widget?.addEventListener(navigateAsEventName, handleNavigateAs);
        widget?.addEventListener(stopNavigateAsEventName, handleStopNavigateAs);
        widget?.addEventListener(signOutEventName, handleSignOut);

        return () => {
            widget?.removeEventListener(navigateAsEventName, handleNavigateAs);
            widget?.removeEventListener(stopNavigateAsEventName, handleStopNavigateAs);
            widget?.removeEventListener(signOutEventName, handleSignOut);
        };
    }, [widget]);

    useEffect(() => {
        if (canNavigateAs) {
            dispatch(getNavigateAsPersons()).catch(() => void 0);
        }
    }, [canNavigateAs]);

    const navigateAsList = navigateAsPersons.map(p => ({
        id: p.icId,
        name: `${p.lastName} ${p.firstName}`,
    }));

    const navigateAsIcId = getNavigateAs();
    const navigateAsUser = navigateAsList.find(user => user.id === navigateAsIcId);

    return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
        <sgwt-account-center
            id="sgwtAccountCenter"
            language="en"
            available-languages="en"
            authentication="sg-connect-v2"
            mode="sg-markets"
            producer-code="sgm_investment_reco"
            environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
            show-sign-in-button={true}
            navigate-as={canNavigateAs}
            navigate-as-list={JSON.stringify(navigateAsList)}
            navigate-as-user={JSON.stringify(navigateAsUser)}
        />
    </WidgetLazyLoader>;
};
