import { useAppContext } from 'components/common/AppProvider';
import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { currentUserHasNoPrivilege, currentUserHasPrivateIrRight } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/SgConnect';
import { getAppContext } from 'store/AppContext/AppContextThunk';

interface ProtectedRouteProps {
    children: React.JSX.Element,
    privatePrivilegeRequired?: boolean,
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, privatePrivilegeRequired }) => {
    const { dispatch, state: { appContext: { isFetching, privileges } } } = useAppContext();

    useEffect(() => {
        (async () => {
            if (!sgConnect?.isAuthorized()) {
                sgConnect?.requestAuthorization();
            }
            else {
                try {
                    const appContext = await dispatch(getAppContext());
                    if (currentUserHasNoPrivilege(appContext.loggedUser?.permissions?? [])) {
                        redirectUnauthorizedUser();
                    }
                    if (privatePrivilegeRequired && !currentUserHasPrivateIrRight(appContext.loggedUser?.permissions ?? [])) {
                        redirectUnauthorizedUser();
                    }
                }
                catch {
                    redirectUnauthorizedUser();
                }
            }
        })();
    });

    if (isFetching) {
        return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
    }
    return currentUserHasNoPrivilege(privileges) || (privatePrivilegeRequired && !currentUserHasPrivateIrRight(privileges))
        ? null : children;
};
