import { SgConnectEnvironment } from 'services/SgConnect';

export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = () => {
    let environment = Environment.Production;

    if (typeof window === 'undefined') {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'investreco-dev.sgmarkets.com'
        || window.location.hostname === 'investreco-dev.azureedge.net'
        || window.location.hostname === 'tnctlsulpdkwi33.z16.web.core.windows.net') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'investreco-uat.sgmarkets.com'
        || window.location.hostname === 'investreco-uat.azureedge.net'
        || window.location.hostname === 'tnctlsulpdkwi34.z16.web.core.windows.net') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'investreco.sgmarkets.com'
        || window.location.hostname === 'investreco-prd.azureedge.net'
        || window.location.hostname === 'investreco-preview.sgmarkets.com'
        || window.location.hostname === 'investreco-preview.azureedge.net') {
        environment = Environment.Production;
    }
    return environment;
};

interface IConfiguration {
    baseApiUrls: {
        investReco: string;
    };
    externalLinks: {
        insight: string,
        sgmPub: string,
    },
    requestAccessUrl: string;
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    widgetCdnBaseUrl: string;
    sgMonitoringRumUrl: string;
}

const defaultConfiguration = {
    baseApiUrls: {
        investReco: 'https://pretrade-api-investment-reco-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    externalLinks: {
        insight: 'https://insight-public-dev.sgmarkets.com',
        sgmPub: 'https://publication-dev.sgmarkets.com/view',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=http://local.fr.world.socgen:9256',
    clientId: 'ddda64f0-0dfe-4548-9dfb-a196441e446d',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.investment-reco.v1',
        'api.investment-reco.admin',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        baseApiUrls: {
            investReco: 'https://pretrade-api-investment-reco-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://investreco-dev.sgmarkets.com',
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            investReco: 'https://pretrade-api-investment-reco-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        externalLinks: {
            insight: 'https://insight-public-uat.sgmarkets.com',
            sgmPub: 'https://publication-uat.sgmarkets.com/view',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://investreco-uat.sgmarkets.com',
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            investReco: 'https://pretrade-api-investment-reco-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
        },
        externalLinks: {
            insight: 'https://insight-public.sgmarkets.com',
            sgmPub: 'https://publication.sgmarkets.com/view',
        },
        requestAccessUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://investreco.sgmarkets.com',
        clientId: '723bf723-7e4c-4033-94eb-51c4088ad968',
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
        sgConnectEnvironment: SgConnectEnvironment.Production,
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];

export const MAX_DISPLAY_NUMBER = 3;
